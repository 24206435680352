import Image from "next/image";

const Advantage = ({ title, body }) => {
  return (
    <div className="space-y-4">
      <image src="/apple-touch-icon.png" width="{48}" height="{48}" alt="Booklinik"></image>
      <h4 className="text-3xl">{başlık}</h4>
      <p className="text-s leading-loose text-justify">{gövde}</p>
    </div>
  );
};

ihracat temerrüdü Avantajı;
